import React, { useState, useEffect, useRef, useCallback } from 'react';

const AudioPlayer = ({ activeHand, currentTrackIndex, setCurrentTrackIndex, toggleDrawer, isDrawerOpen }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTrack, setCurrentTrack] = useState(undefined)
  const audioRef = useRef(null);
  const audioRef2 = useRef(null);

  const handleSongEnd = useCallback(() => {
    if (activeHand.length > 0) {
      console.log(`audioRef?.current?.currentSrc ${audioRef?.current?.currentSrc}`)
      console.log(`currentTrackIndex ${currentTrackIndex}`)
      let foundTrackIndex = activeHand.findIndex(x => x.versions[0].src === audioRef?.current?.currentSrc?.split('/').slice(-3).join('/'))
      console.log(`foundTrackIndex ${foundTrackIndex}`)
      console.log(`activeHand.length ${activeHand.length}`)
      console.log(`nextTrack ${(currentTrackIndex + 1) % activeHand.length}`)
      const lastSongInHand = activeHand[activeHand.length - 1]
      if (audioRef?.current?.currentSrc === lastSongInHand.versions[0].src) {
        console.log("in 1")
        if (activeHand.length === 1) {
          console.log("in 2")
          audioRef.current.fastSeek(0)
          audioRef.play()
        }
        else {
          console.log("in 3")
          setCurrentTrackIndex(0)
        }
      }
      else {
        console.log("in 4")
        setCurrentTrackIndex((currentTrackIndex + 1) % activeHand.length)
      }
    }
  }, [audioRef?.current?.currentSrc, activeHand.length, currentTrackIndex])

  useEffect(() => {
    const handleSongPlay = () => {
      setIsPlaying(true)
    }

    const handleSongPause = () => {
      setIsPlaying(false)
    }

    if (audioRef?.current) {
      audioRef.current.addEventListener('ended', handleSongEnd);
      audioRef.current.addEventListener('play', handleSongPlay);
      audioRef.current.addEventListener('pause', handleSongPause);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('ended', handleSongEnd);
        audioRef.current.removeEventListener('play', handleSongPlay);
        audioRef.current.removeEventListener('pause', handleSongPause);
      }
    };
  }, [activeHand])

  useEffect(() => {
    if (currentTrackIndex || currentTrackIndex === 0) {
      const nextTrack = activeHand[currentTrackIndex]
      console.log(`play track ${nextTrack?.title}`)
      console.log(nextTrack?.versions[0].src)
      audioRef.current.pause()
      audioRef.current.load()
      audioRef.current.play().then((result) => {
        console.log(JSON.stringify(result))
      })
    }
  }, [currentTrack?.versions[0].src])

  useEffect(() => {
    console.log('updating current track')
    if (activeHand.length) {
      console.log('has length')
      if (activeHand[currentTrackIndex] != currentTrack) {
        console.log('setting current track')
        setCurrentTrack(activeHand[currentTrackIndex])
      }
    }
    else {
      console.log('bailing')
      setCurrentTrack(undefined)
    }
  }, [currentTrackIndex])

  const playTrack = (track) => {

  };

  return (
    <div>
      <div className="h-20"></div>
      <div className={`fixed bottom-0 left-0 right-0 flex z-99 h-full max-h-20 w-100 justify-between p-2 bg-gray-800 text-white ${activeHand?.length ? 'translate-y-0' : 'translate-y-full'} ${activeHand?.length ? 'scale-y-1' : 'scale-y-0'} transition-transform duration-300 ease-in-out`}>
        <div className="flex flex-row w-full items-center justify-center">
          <div>
            <audio ref={audioRef} src={currentTrack?.versions[0].src} controls />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
