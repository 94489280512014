import React, { useState } from 'react';
import SongCard from './SongCard';

const songStyles = [
  "Dreamscape",
  "Sunny Swing",
  "Bard’s Guitar",
  "Echo Rock",
  "Deep Sea Strings",
  "Neon Synths",
  "Sailor’s Waltz",
  "Ambient Meditation",
  "Bossa Novelty",
  "Jazz"
]

const ActiveCard = ({ activeCard, toggleDrawer }) => {
  const [activeStyle, setActiveStyle] = useState(songStyles[0])

  return (
    <div className="flex flex-row justify-center items-center">
      <div className="p-4 flex flex-col justify-center align-center">
        <SongCard
          song={activeCard}
          onClick={() => toggleDrawer()}
        />
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="p-4 flex flex-col flex-wrap justify-center">
          {songStyles.map((songStyle, idx) => {
            return <div className="p-1">
              <button
                className={`${songStyle === activeStyle ? "bg-green-600 hover:bg-green-700 text-green-100" : "bg-gray-300 hover:bg-gray-400 text-gray-800"} text-xs font-bold py-2 px-4 rounded inline-flex justify-center items-center whitespace-nowrap w-full`}
                onClick={() => setActiveStyle(songStyles[idx])}
                key={idx}
              >
                <span>{songStyle}</span>
              </button>
            </div>
          })}
        </div>
        <div className="p-4 w-full">
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 text-xs font-bold py-2 px-4 rounded inline-flex justify-center items-center whitespace-nowrap w-full"
            onClick={() => toggleDrawer()}
          >
            <span>Change Card</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ActiveCard;
