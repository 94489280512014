import React, { useState, useEffect, useRef } from 'react';
import SongCard from './SongCard';

import images from '../assets/images/images'
import audio from '../assets/audio/audio'

//import diamonds2ImgSm from '../assets/images/c1-01-320.jpg'
//import diamonds2ImgLg from '../assets/images/c1-01-1000.jpg'

const suits = ['Diamonds', 'Spades', 'Hearts', 'Clubs'];
const ranks = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];

const songs = Array.from({ length: 52 }, (_, index) => {
  const suitIndex = Math.floor(index / 13);
  const rankIndex = index % 13;
  return {
    id: index + 1,
    title: `${ranks[rankIndex]} of ${suits[suitIndex]}`,
    artwork: images[suitIndex][rankIndex][0],
    rank: rankIndex,
    suit: suitIndex,
    versions: [
      { id: 'v1', src: audio[suitIndex][rankIndex][0] },
      { id: 'v2', src: audio[suitIndex][rankIndex][1] },
      { id: 'v3', src: audio[suitIndex][rankIndex][2] },
    ],
  };
});

const Drawer = ({ addToActiveHand, toggleDrawer, isOpen }) => {
  const [sortKey, setSortKey] = useState('rank');
  const drawerRef = useRef(null);

  const sortedSongs = [...songs].sort((a, b) => a[sortKey] > b[sortKey]);

  //  const closeDrawer = () => setIsOpen(false);

  return (


    <div
      ref={drawerRef}
      className={`fixed bottom-0 h-full max-h-svh w-full shadow-lg overflow-y-scroll transform ${isOpen ? 'translate-y-0' : 'translate-y-full'} ${isOpen ? 'scale-y-1' : 'scale-y-0'} transition-transform duration-300 ease-in-out z-1`}
    >
      <div className="troub-background-dark p-4 h-100 ">
        <div className="flex justify-end items-center py-3 top-0">
          <button className="mr-2 bg-blue-200 p-2 rounded" onClick={() => setSortKey('rank')}>Sort by Rank</button>
          <button className="p-2 bg-blue-200 rounded" onClick={() => setSortKey('suit')}>Sort by Suit</button>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 px-7 gap-7 mt-4 h-100 ">
          {sortedSongs.map(song => (
            <SongCard showPlay key={song.id} song={song} onClick={() => { addToActiveHand(song); toggleDrawer() }} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Drawer;